import SEO from 'modules/common/components/SEO';
import HeaderLayout from 'modules/common/layouts/HeaderLayout';
import type { NextPage } from 'next';
import Link from 'next/link';
// import FeaturesList from 'modules/Marketing/components/FeaturesList';
// import GetInfoSection from 'modules/Marketing/components/GetInfoSection';
// import MissionOrCandidate from 'modules/Marketing/components/MissionOrCandidate';
import { track } from '@/lib/analytics/client';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import Markdown from 'lib/components/ui/markdown';
import { formatCurrency } from 'lib/helpers/strings';
import { event, trackAskDemo } from 'modules/Analytics';
import { whatsappDorothee } from 'modules/App/config';
import { LIST_EVENTS } from 'modules/App/data-components/EventCalendar';
import { Event } from 'modules/App/models/Event';
import { useAuthModal } from 'modules/auth';
import Button from 'modules/common/components/Button';
import Hero from 'modules/common/components/Hero';
import Highlight from 'modules/common/components/Highlight';
import Section from 'modules/common/components/Section';
import StatsSection from 'modules/common/components/Stats';
import TestimonialOfferList from 'modules/App/components/TestimonialOfferList';
import usePageProps from 'modules/common/hooks/usePageProps';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import ImageSection from 'modules/Marketing/components/ImageSection';
import RotatingFeatures from 'modules/Marketing/components/RotatingFeatures';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import { Md11Mp, MdCalendarViewDay, MdGroup, MdProductionQuantityLimits, MdWorkspaces } from 'react-icons/md';
import { formatMinutesToHoursAndMinutes } from 'utils/misc';
import PlanComparison from '../components/PlanComparison';
import Pricing from '../components/Pricing';
import { FaCocktail } from 'react-icons/fa';

// const BulletSvg = () => (
//   <svg className="shrink-0 mr-3" width="20" height="20" xmlns="http://www.w3.org/2000/svg">
//     <circle className="fill-primary" cx="10" cy="10" r="10" />
//     <path
//       className="fill-white"
//       d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
//     />
//   </svg>
// );

export const mainFeatures = (
  <RotatingFeatures
    className="mt-5"
    title="Valorise ton vivier de candidats et accélère tes process"
    subtitle={
      false && (
        <>
          L’objectif d’Headlinker est de te permettre d’<strong>augmenter tes revenus</strong>, la <strong>satisfaction</strong> de tes clients et
          celle de tes candidats, grâce à notre plateforme <strong>sécurisée</strong>
        </>
      )
    }
    items={[
      {
        id: 'candidate',
        title: <>Ton candidat n‘a pas été choisi par ton client&nbsp;?</>,
        subtitle: 'N’attends pas qu’il soit déçu, ou qu’il signe ailleurs, sans toi',
        icon: <MdCalendarViewDay />,
        details: (
          <div className="prose prose-sm text-left">
            <h2>Propose ton candidat sur Headlinker</h2>
            <ol>
              <li>Propose ton candidat de manière anonyme</li>
              <li>Décris sa recherche</li>
              <li>Demande la prime qui te semble juste pour le proposer préqualifié et actif à la communauté</li>
              <li>Reçois des propositions de mission d’autres recruteurs professionnels</li>
              <li>S’il va au bout du process, empoche ta prime.</li>
            </ol>
            <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison
              items={[
                {
                  plan: 'starter',
                  title: (
                    <>
                      <strong>10%</strong> de la prime
                    </>
                  ),
                  details: (
                    <>
                      Exemple:
                      <br />
                      Candidat placé pour une prime de <strong>4 500€</strong>
                      <br />
                      <strong>450€</strong> à payer à Headlinker
                    </>
                  ),
                },
                {
                  plan: 'pro',
                  title: <strong>Aucun frais</strong>,
                  details: (
                    <>
                      Exemple:
                      <br />
                      Candidat placé pour une prime de <strong>4 500€</strong>
                      <br />
                      <strong>0€</strong> à payer à Headlinker
                    </>
                  ),
                },
              ]}
            ></PlanComparison>
          </div>
        ),
      },
      {
        id: 'mission',
        title: <>Tu ne trouves pas de candidat pour la mission de ton client&nbsp;?</>,
        subtitle: 'Tu n’as pas assez de temps ? La mission est complexe ?',
        icon: <Md11Mp />,
        details: (
          <div className="prose prose-sm text-left">
            <h2>Propose ta mission sur Headlinker</h2>
            <ol>
              <li>Décris le détail de ta mission</li>
              <li>Détermine le montant de la prime que tu es prêt à verser</li>
              <li>Valide ta mission sur notre plateforme sécurisée</li>
              <li>Reçois des propositions de candidats</li>
              <li>Présente les candidats proposés à ton client</li>
              <li>Si l’un d’eux signe chez ton client, paye la prime au recruteur qui te l’aura présenté</li>
            </ol>
            <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison
              items={[
                {
                  plan: 'starter',
                  title: (
                    <>
                      <strong>10%</strong> de la prime
                    </>
                  ),
                  details: (
                    <>
                      Exemple:
                      <br />
                      Candidat placé pour une prime de <strong>4 500€</strong>
                      <br />
                      <strong>450€</strong> à payer à Headlinker
                    </>
                  ),
                },
                {
                  plan: 'pro',
                  title: <strong>Aucun frais</strong>,
                  details: (
                    <>
                      Exemple:
                      <br />
                      Candidat placé pour une prime de <strong>4 500€</strong>
                      <br />
                      <strong>0€</strong> à payer à Headlinker
                    </>
                  ),
                },
              ]}
            ></PlanComparison>
          </div>
        ),
      },
      {
        id: 'search',
        title: <>Tu veux gagner du temps sur une mission ?&nbsp;?</>,
        subtitle: 'Consulte notre CVThèque Off-Market exclusive de candidats actifs ET passifs',
        icon: <MdProductionQuantityLimits />,
        details: (
          <div className="prose prose-sm text-left">
            <h2>Consulte la CVthèque exclusive Headlinker</h2>
            <ol>
              <li>Cette base est constituée de candidats actifs ET passifs, sourcés par nos recruteurs, et à l’écoute du marché.</li>
              <li>Choisis celui qui t’intéresse et accède immédiatement aux coordonnées du recruteur de ce candidat</li>
              <li>
                Présente-lui ta mission, s’il pense que son candidat peut être intéressé, il le contacte pour obtenir son accord, et valide ta
                proposition
              </li>
              <li>L’identité du candidat t’est alors dévoilée : tu peux le contacter et le présenter à ton client</li>
              <li>S’il va au bout du process de ton client, tu paies la prime prévue intialement avec le recruteur.</li>
            </ol>
            <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison
              items={[
                {
                  plan: 'starter',
                  title: (
                    <>
                      <strong>10%</strong> de la prime
                    </>
                  ),
                  details: (
                    <>
                      Exemple:
                      <br />
                      Candidat placé pour une prime de <strong>4 500€</strong>
                      <br />
                      <strong>450€</strong> à payer à Headlinker
                    </>
                  ),
                },
                {
                  plan: 'pro',
                  title: <strong>Aucun frais</strong>,
                  details: (
                    <>
                      Exemple:
                      <br />
                      Candidat placé pour une prime de <strong>4 500€</strong>
                      <br />
                      <strong>0€</strong> à payer à Headlinker
                    </>
                  ),
                },
              ]}
            ></PlanComparison>
          </div>
        ),
      },
    ]}
  />
);

const pricingAtelierItems: React.ComponentProps<typeof PlanComparison>['items'] = [
  {
    plan: 'none',
    title: (
      <>
        <strong>150€</strong>
      </>
    ),
  },
  {
    plan: 'starter',
    title: (
      <>
        <strong>90€</strong>
      </>
    ),
    details: (
      <>
        Economie de <strong>50€</strong>
      </>
    ),
  },
  {
    plan: 'pro',
    title: <strong>Gratuit *</strong>,
    details: (
      <>
        Economie de <strong>150€</strong>
        <br />* Dans la limite de 4 ateliers par mois
      </>
    ),
  },
];

export const bonusFeatures = (
  <RotatingFeatures
    title="Outils, Formations, Trainings... moins chers !"
    subtitle={
      <>
        Pour un recrutement <strong>efficace</strong>, tu dois savoir <strong>prospecter</strong>, <strong>connaître</strong> ta cible, avoir une{' '}
        <strong>offre solide</strong>, et <strong>t’imposer</strong> face à tes clients. Il te faut aussi un <strong>outil de sourcing</strong> ou
        l’accès à une
        <strong>CVthèque</strong>, un bon <strong>ATS</strong>, et enfin <strong>Headlinker</strong> pour valoriser tes candidats recalés.
        <br />
        Alors on t’a organisé tout ça, et on a même négocié les prix pour toi.
      </>
    }
    items={[
      {
        id: 'prospection-collective',
        title: '⁠Tu as du mal à t’organiser ? Tu procrastines la prospection ?',
        subtitle: 'Rejoins nos séances de prospection collective !',
        type: 'workshop',
        icon: <MdWorkspaces />,
        details: (
          <div className="prose prose-sm text-left">
            <Markdown>
              {`**Le mardi matin, et le jeudi après midi, on prospecte ensemble dans notre maison virtuelle**
- Tu t’inscris directement dans l’agenda
- Tu te connectes avec ta liste de prospection
- Tu papotes dans la salle café avec d’autres recruteurs pendant 15 min max
- Et tu pars dans une salle dédiée avec un ou 2 autres recruteurs pour 1h30 de travail
- À la fin de la session, tu as terminé ta prospection pour la semaine :)

[Voir l'offre](/#pricing)
`}
            </Markdown>
            {/* <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison items={pricingAtelierItems} /> */}
          </div>
        ),
      },
      {
        id: 'team',
        title: 'Tu as besoin d’une vraie équipe pour passer à l’étape supérieure ?',
        subtitle: 'Rejoins un club Team Booster mais reste indépendant !',
        type: 'tool',
        icon: <MdGroup />,
        details: (
          <div className="prose prose-sm text-left">
            <Markdown>
              {`**Les Clubs Headlinker**

- Dorothée te crée une équipe sur mesure, en fonction de ta personnalité et de tes objectifs
- le lundi matin, ton équipe se réunit pour échanger sur les succès et échecs de la semaine passée et chacun fixe ses objectifs prioraires de la semaine. Hot seat à tour de rôle : chaque semaine, un membre soumet aux autres un pb et sollicite leur avis.
- l’équipe se retrouve le mardi et/ou le jeudi en session de prospection.
- Intervention régulière de Dorothée, d’une coach, d’experts, pour progresser en continu
- Accès à un groupe WhatsApp dédié
- **ATTENTION** : liste d’attente importante

[Voir l'offre](/#pricing)
`}
            </Markdown>
            {/* <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison items={pricingAtelierItems} /> */}
          </div>
        ),
      },
      {
        id: 'trimoji',
        title: 'L’outil d’évaluation de personnalité : Trimoji',
        subtitle: 'Simplifie tes processus de recrutement et augmente ta valeur',
        icon: <MdCalendarViewDay />,
        type: 'tool',
        details: (
          <div className="prose prose-sm text-left">
            <Image src="/images/tools/trimoji.png" width={125} height={38} alt="Trimoji" />
            <Markdown>
              {`## Trimoji c’est quoi?
              
Découvre [Trimoji](https://trimoji.fr/)

- **Augmente** la valeur perçue par tes clients
- Ajoute à ton arsenal de recruteur un outil d'**audit d'équipe facturable** immédiatement
- **Améliore ton image** auprès de tes candidats en leur fournissant leur évaluation de personnalité
- Utilise l'outil en marque blanche et **reste indépendant**
- Regarde le [Replay de présentation](https://www.youtube.com/watch?v=gMXd7IdJGNM)
- Crée ton compte Headlinker et [prends rendez-vous](https://calendly.com/davy-correia/rendez-vous-decouverte-trimoji-x-headlinker) dès maintenant pour bénéficier de la remise réservée aux membres
`}
            </Markdown>

            <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison
              items={[
                {
                  plan: 'none',
                  title: (
                    <>
                      <strong>300€/mois</strong>
                      <br />
                      Formation à <strong>900€</strong>
                    </>
                  ),
                },
                {
                  plan: 'starter',
                  title: (
                    <>
                      <strong>250€/mois</strong>
                      <br />
                      Formation à <strong>600€</strong>
                    </>
                  ),
                  details: (
                    <>
                      Economie de <strong>17%</strong> sur l’abonnement
                      <br />
                      et <strong>33%</strong> sur la formation
                    </>
                  ),
                },
                {
                  plan: 'pro',
                  title: (
                    <>
                      <strong>200€/mois</strong>
                      <br />
                      Formation à <strong>450€</strong>
                    </>
                  ),
                  details: (
                    <>
                      Economie de <strong>33%</strong> sur l’abonnement
                      <br />
                      et <strong>50%</strong> sur la formation
                    </>
                  ),
                },
              ]}
            ></PlanComparison>
          </div>
        ),
      },
      {
        id: 'prospection',
        title: 'Formation "3 placements minimum en 90 jours"',
        subtitle: 'Prospection et stratégie commerciale + nouvelles méthodes de sourcing',
        icon: <Md11Mp />,
        type: 'training',
        details: (
          <div className="prose prose-sm text-left">
            <Image src="https://files.headlinker.com/users/linkedin/Pg7-Fm6yps.jpeg" width={64} height={64} alt="Trimoji" />
            <Markdown>
              {`
### Avec [Kemra Jaafar](https://recrutech-and-co.fr/) de Recrutech & Co

- Coaching 1 to 1 avec des experts en **prospection**
- Coaching 1 to 1 avec Kemra pour **co-construire ta stratégie** de recrutement et ta **prospection commerciale**
- **6h** de vidéos sur les nouvelles méthodes de sourcing 3.0 (sourcing sur canaux non conventionnels, IA, copywriting etc.)
`}
            </Markdown>
            <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison
              items={[
                {
                  plan: 'none',
                  title: (
                    <>
                      <strong>2 300€</strong>
                    </>
                  ),
                },
                {
                  plan: 'starter',
                  title: (
                    <>
                      <strong>2 150€</strong>
                    </>
                  ),
                  details: (
                    <>
                      Economie de <strong>150€</strong>
                    </>
                  ),
                },
                {
                  plan: 'pro',
                  title: <strong>2 000€</strong>,
                  details: (
                    <>
                      Economie de <strong>300€</strong>
                    </>
                  ),
                },
              ]}
            ></PlanComparison>
          </div>
        ),
      },
      //       {
      //         id: 'cold-call',
      //         title: 'Développe ta base de clients grâce au Cold Call',
      //         subtitle: 'Valide ton offre et décroche des rendez-vous en 3h',
      //         type: 'workshop',
      //         icon: <Md11Mp />,
      //         details: (
      //           <div className="prose prose-sm text-left">
      //             <Image src="/images/tools/vanessa-amsili.jpg" width={129} height={150} alt="Vanessa Amsili" />
      //             <Markdown
      //               components={{
      //                 a: ({ children, href }) => (
      //                   <a href={href} target="_blank" rel="noreferrer">
      //                     {children}
      //                   </a>
      //                 ),
      //               }}
      //             >
      //               {`## Animé par [Vanessa Amsili](https://www.linkedin.com/in/vanessa-amsili)

      // - **avant l'atelier**, tu prépares ta liste de numéros, on t'envoie un guide, tu prépares ton pitch, et on te le corrige.
      // - **pendant l'atelier**, tu poses tes questions, on parle des objections tous les 12 (vous 10, Vanessa, et moi)
      // - et ensuite on vous met par groupe de 2, et vous commencez à appeler.

      // L'énergie est folle, personne n'a envie de s'arrêter au bout de 3h.
      // `}
      //             </Markdown>
      //             <h2>Combien ça coûte&nbsp;?</h2>
      //             <PlanComparison items={pricingAtelierItems} />
      //           </div>
      //         ),
      //       },
      {
        id: 'requete-booleenne',
        title: 'Développe ta base de candidats avec Benoît Bliard',
        subtitle: 'Google Search - Initiation à l’Open web sourcing',
        type: 'workshop',
        icon: <Md11Mp />,
        details: (
          <div className="prose prose-sm text-left">
            <Image src="/images/tools/benoit-bliard.jpg" width={129} height={150} alt="Benoît Bliard" />
            <Markdown>
              {`## Animé par [Benoît Bliard](https://www.linkedin.com/in/benoitbliard)

Comment une requête avancée sur Google peut vous permettre d'identifier n'importe quels CV, profils ou compétences sur l'Open Web : réseaux sociaux, sites communautaires, site écolé, sites entreprises, hébergeurs de CV, blogs, pages personnels, etc.
      `}
            </Markdown>
            {/* <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison items={pricingAtelierItems} /> */}
          </div>
        ),
      },
      {
        id: 'leadership',
        title: 'Leadership: Passe au niveau supérieur',
        subtitle: 'Audit personnalisé de ton activité',
        type: 'workshop',
        icon: <Md11Mp />,
        details: (
          <div className="prose prose-sm text-left">
            <Image src="/images/tools/kemra-jaafar.jpg" width={150} height={150} alt="Joanna Bouy" />
            <Markdown>
              {`## Animé par [Kemra Jaafar](https://www.linkedin.com/in/kemra-jaafar-4644b8173) de Recrutech & Co
   
- 30 min d’échange sur tous les pans de ton activité (prospection, facturation, sourcing, recouvrement, organisation, outils …)
+ 15 min de plan d’action
`}
            </Markdown>
            <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison items={pricingAtelierItems}></PlanComparison>
          </div>
        ),
      },
      //       {
      //         id: 'leadership',
      //         title: 'Développe ton Leadership',
      //         subtitle: 'Pose les limites avec ton client pour mieux appréhender la négociation',
      //         type: 'workshop',
      //         icon: <Md11Mp />,
      //         details: (
      //           <div className="prose prose-sm text-left">
      //             <Image src="/images/tools/joanna-bouy.jpg" width={150} height={150} alt="Joanna Bouy" />
      //             <Markdown>
      //               {`## Animé par [Joanna Bouy](https://www.linkedin.com/in/joanna-bouy)

      // - Cas concrets: à partir cas précis (négociations tarifaires, demandes de clients déraisonnables, etc.)
      // `}
      //             </Markdown>
      //             <h2>Combien ça coûte&nbsp;?</h2>
      //             <PlanComparison items={pricingAtelierItems}></PlanComparison>
      //           </div>
      //         ),
      //       },
      {
        id: 'hello-work',
        title: 'Accès Hello Work',
        subtitle: 'la référence en matière de CVThèque',
        icon: <Md11Mp />,
        type: 'tool',
        details: (
          <div className="prose prose-sm text-left">
            <Image src="/images/tools/hello-work.png" width={125} height={38} alt="Hello Work" />
            <Markdown>
              {`
- Base de données de CVs qualifiés 
- Fonctionnalités de recherche avancées
- Gestion des candidatures et suivi des candidats
- Utilise l'outil en marque blanche et **reste indépendant**
`}
            </Markdown>
            <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison
              items={[
                {
                  plan: 'none',
                  title: (
                    <>
                      Environ <strong className="whitespace-nowrap">8 000€</strong>
                    </>
                  ),
                },
                {
                  plan: 'starter',
                  title: <>Pas encore négocié</>,
                  details: (
                    <>
                      Interessé? Contacte{' '}
                      <a href={whatsappDorothee} target="_blank" rel="noopener noreferrer">
                        Dorothée
                      </a>
                    </>
                  ),
                },
                {
                  plan: 'pro',
                  title: <>Pas encore négocié</>,
                },
              ]}
            ></PlanComparison>
          </div>
        ),
      },
      {
        id: 'apero',
        title: '⁠Cafés virtuels, apéros en présentiel',
        subtitle: 'Viens échanger avec d’autres recruteurs des meilleurs outils, et pratiques du marché',
        type: 'apero',
        icon: <FaCocktail />,
        details: (
          <div className="prose prose-sm text-left">
            <Markdown>
              {`Sors de la solitude pour atteindre ton plein potentiel

**Le vendredi à 10h, on se retrouve pour un café virtuel :**
- Tu te connectes directement depuis le calendrier Headlinker
- Les sujets sont libres
- Tu as la possibilité de t’isoler dans une salle avec 1 ou 2 autres recruteurs s’il y a plusieurs conversations

**1 fois par mois, on organise un apéro, en présentiel, à Nantes, Bordeaux, Toulouse, Québec, Montréal ou Paris**
- Regarde dans notre onglet [Ambassadeurs](/ambassadors), s’il y en a un dans ta ville, c’est lui qui organise les apéros ! 
- Dès que ton inscription est validée, tu rejoins son groupe WhatsApp pour être informé des prochaines dates
- Si tu veux devenir Ambassadeur Headlinker dans ta ville, envoie un message à Dorothée !
- Rien de tel pour créer de vraies relations
`}
            </Markdown>
            {/* <h2>Combien ça coûte&nbsp;?</h2>
            <PlanComparison items={pricingAtelierItems} /> */}
          </div>
        ),
      },
    ]}
  />
);

const HomePage: NextPage = () => {
  const { toggleModal, session } = useAuthModal();
  const router = useRouter();
  const { whatsappDorothee, youtubeChannelUrl } = usePublicRuntimeConfig();
  const { pageProps: { stats } = {} as any } = usePageProps() || {};

  const { data } = useQuery(LIST_EVENTS, { variables: {} });
  const { items: events = [] } = data?.events || {};

  const [seenVideo, setSeenVideo] = React.useState(false);
  const videoRef = React.useRef<HTMLIFrameElement>(null);

  const signin = (url = '/my-missions') => {
    !!session ? router.push(url) : toggleModal();
  };

  const items = [
    {
      value: stats?.nbMembers,
      label: 'Membres actifs',
      moreInfo: (
        <>
          et <strong>sympas</strong>
        </>
      ),
    },
    {
      value: stats?.nbSearchedProfiles,
      label: 'Postes à pourvoir',
      moreInfo: 'tout de suite',
      aosDelay: 100,
    },
    {
      value: dayjs().diff(dayjs('2024-04-01'), 'week'),
      label: 'Cafés Headlinker',
      moreInfo: 'networking facile',
      aosDelay: 200,
    },
    {
      value: stats?.nbAvailableProfiles,
      label: 'Candidats Off-Market',
      moreInfo: 'dispos maintenant',
      aosDelay: 100,
    },
    // { value: stats?.nbContacts, label: 'Candidats recommandés', aosDelay: 200 },
    {
      value: formatCurrency({ value: stats?.bountyAmount, locale: 'fr' }),
      label: 'Primes à octroyer',
      moreInfo: 'place ton candidat',
      aosDelay: 200,
    },
  ];

  const abtests = {
    hero: {
      title: (
        <>
          <span className="block mb-5 uppercase text-4xl">Recruteur externe</span>
          Délègue ton <Highlight>sourcing</Highlight>
          <br />
          <Highlight>Valorise</Highlight> ton vivier de candidats
        </>
      ),
      subtitle: (
        <>
          Grâce à notre communauté de confiance et notre plateforme sécurisée, <strong>gagne du temps</strong> et{' '}
          <strong>augmente tes revenus</strong> sans travail supplémentaire
        </>
      ),
    },
    hero2: {
      title: <>Transforme ta boîte actuelle en celle dont tu as toujours rêvé !</>,
      subtitle: (
        <>
          Gérer le quotidien et structurer sa société sont 2 sujets difficiles à mener de front. Remplis notre questionnaire pour être guidé pas à
          pas.
        </>
      ),
    },
  };
  const abtest = abtests.hero;

  return (
    <HeaderLayout title="Headlinker" description="La meilleure communauté de confiance pour recruteurs">
      <SEO />
      <Hero
        // meta={<LinkBadge href="#vision" title="Découvre notre vision" badgeText="💡" />}
        title={abtest.title}
        description={abtest.subtitle}
        cta={
          <div>
            <Button
              onClick={() => {
                signin();
                event({ action: 'cta-home-hero', category: 'home', label: 'cta-home-hero' });
              }}
              className="btn-primary btn"
              badge
            >
              Je deviens un Headlinker
            </Button>
            <div className="mt-3">
              <Link
                href="/test-recruteur-independant"
                className="link link-primary text-sm"
                target="_blank"
                onClick={() => track('test-recruteur-independant.clicked')}
              >
                Je passe le test du recruteur indépendant en 3 min
              </Link>
            </div>
          </div>
        }
      />
      <StatsSection items={items} />
      <Section bgColor="bg-dark text-white" title={'Ils parlent de nous'} id="testimonials">
        <TestimonialOfferList type="stars" className="my-12" />
      </Section>
      <Section
        className="bg-gray-50"
        title={<>Headlinker, ç’est pour qui&nbsp;?</>}
        subtitle={
          <>
            Headlinker est la plateforme dédiée aux recruteurs indépendants et cabinets qui souhaitent optimiser leur activité, développer leur réseau
            et maximiser leurs placements grâce à un écosystème collaboratif et sécurisé.
          </>
        }
      >
        <div className="aspect-video w-full max-w-3xl mx-auto rounded-lg overflow-hidden shadow-lg mt-8 relative">
          {!seenVideo && (
            <div
              className="absolute inset-0 z-10 cursor-pointer video-overlay bg-transparent"
              onClick={() => {
                track('video.played', {
                  video_title: 'Présentation Headlinker',
                  video_id: '9x7S2hrWIzU',
                });

                videoRef.current?.click();
                setSeenVideo(true);
              }}
            />
          )}
          <iframe
            ref={videoRef}
            className="w-full h-full"
            src="https://www.youtube.com/embed/9x7S2hrWIzU"
            title="Présentation Headlinker"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="text-center mt-4">
          <button
            className="btn btn-outline btn-sm"
            onClick={() => {
              track('video.see-all', {
                source: 'homepage',
              });
              window.open(youtubeChannelUrl, '_blank');
            }}
          >
            Voir toutes nos vidéos
          </button>
        </div>
      </Section>
      <Section
        title={<>Headlinker, ça sert à quoi&nbsp;?</>}
        subtitle={
          <>
            L’objectif d’Headlinker est de permettre aux recruteurs externes d’augmenter leurs revenus, la satisfaction de leurs clients et celle de
            leurs candidats, grâce à notre plateforme sécurisée.
          </>
        }
      >
        {mainFeatures}
      </Section>
      <ImageSection />
      <Section title={<>Super ! Il y a d’autres avantages&nbsp;?</>} subtitle={<></>} id="features">
        {bonusFeatures}
      </Section>
      <Section className="bg-gray-50" title={<>Prochains évènements</>} subtitle={<></>} id="events">
        <ul className="flex items-stretch gap-2 flex-wrap mt-5 justify-center">
          {([...events] as Event[])
            .sort((a, b) => dayjs(a.startAt).diff(dayjs(b.startAt)))
            .filter((event) => dayjs(event.startAt).isAfter(dayjs()))
            .filter((event, index, array) => (event.tool?.type === 'coffee' ? array.findIndex((e) => e.tool?.type === 'coffee') === index : true))
            .map((event) => (
              <li key={`${event.name || event.tool.name}_${event.startAt}`} className="border border-gray-300 rounded py-1 px-2 list-none w-56">
                <span className="h6 block font-bold">{event.name || event.tool.name}</span>
                <div>
                  <time className="textd-gray-800 text-sm" dateTime={dayjs(event.startAt).format()}>
                    {dayjs(event.startAt).format('LLL')}
                  </time>{' '}
                  <strong className="text-sm block">{formatMinutesToHoursAndMinutes(event.duration || event.tool.duration || 0)}</strong>
                </div>
              </li>
            ))}
          <li className="border border-gray-300 rounded py-1 px-2 list-none">
            <span className="h6 block font-bold">+{events.filter((event: Event) => dayjs(event.startAt).isAfter(dayjs())).length - 4}</span>
            <div>&nbsp;</div>
          </li>
        </ul>
        <div className="text-center mt-5">
          <button onClick={() => signin('/calendar')} className="link link-primary text-sm mt-2">
            Voir tous les évènements
          </button>
        </div>
      </Section>
      <Section
        id="pricing"
        title={<>Et ça coute combien Headlinker&nbsp;?</>}
        subtitle="Notre service de base est soumis à une cotisation annuelle et une paiement au succès, afin que vous puissiez vous familiariser facilement avec la plateforme et la communauté"
      >
        <Pricing className="mt-5" withSubscribeButton={false} />
        <div className="text-center mt-5">
          <p className="text-sm text-gray-500 mt-12 mb-2">
            Accès immédiat à une communauté expérimentée et de confiance pour vous accompagner vers des résultats rapides.
          </p>
          <Button className="btn btn-primary" onClick={toggleModal}>
            J’accède à la plateforme
          </Button>
        </div>
      </Section>
      {/* <MissionOrCandidate /> */}
      {/* <GetInfoSection /> */}

      {/* <FeaturesList /> */}
      <Section
        title="Ça parait super cool, j’ai besoin de parler à quelqu'un d'abord"
        subtitle="Pas de problèmes, nous sommes là pour répondre à toutes tes questions"
        className="text-center"
        bgColor="bg-dark text-white"
      >
        <Link scroll={false} className="btn btn-accent mx-auto mt-5" target="_blank" href={whatsappDorothee} onClick={() => trackAskDemo()}>
          J’appelle Dorothée
        </Link>
        <Link className="btn btn-outline btn-info mx-auto mt-5 ml-5" href={'/ambassadors'} onClick={() => trackAskDemo()}>
          J’appelle un ambassadeur
        </Link>
      </Section>
      <Section id="faq" title="FAQ" subtitle="Questions fréquentes">
        <section className="faq mt-5" itemScope itemType="https://schema.org/FAQPage">
          <article itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Quand avoir recours à nos services ?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Vous décrochez un mandat, mais :<br />
                <ul className="mt-5">
                  <li>Vous connaissez mal le poste ou le secteur, votre base de candidat n’est pas adaptée</li>
                  <li>Vous traversez une période de forte activité et vous sentez dépassé par la charge de travail</li>
                  <li>Le poste est complexe à pourvoir, les délais sont particulièrement serrés</li>
                  <li>
                    La perspective de passer une semaine sur LinkedIn puis au téléphone à essayer de convaincre des candidats indécis ne vous enchante
                    guère
                  </li>
                </ul>
              </div>
            </div>
          </article>
          <article itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Quelle est la différence entre Hunteed et Headlinker ?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <p itemProp="text">
                <strong>Hunteed</strong> est une plateforme de recrutement qui aide les entreprises à gérer leurs recrutements en Europe en offrant
                une solution digitale et l‘expertise de milliers de cabinets de recrutement.
                <br />
                <br />
                <strong>Headlinker</strong>, en revanche, est une plateforme de collaboration réservée aux professionnels du recrutement, permettant
                aux recruteurs de publier leurs annonces, de fixer des primes pour les recruteurs qui leur trouveront le bon candidat, et d‘accéder à
                une liste d‘offres confidentielles pour proposer leurs meilleurs candidats.
                <br />
                <br />
                En somme, Hunteed est destiné aux entreprises qui cherchent à externaliser leurs recrutements, tandis que Headlinker est destiné aux
                professionnels du recrutement qui cherchent à trouver des candidats pour leur mission ou place ceux qui ne souhaitent pas travailler
                avec leurs clients.
              </p>
            </div>
          </article>
          <article itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Quelle est la différence entre Muzzo et Headlinker ?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <p itemProp="text">
                <strong>Muzzo</strong> et <strong>Headlinker</strong> sont deux plateformes de recrutement, mais elles servent des publics différents
                et ont des modèles opérationnels distincts.
                <br />
                <br />
                <strong>Muzzo</strong> est conçu pour les employeurs qui cherchent à embaucher rapidement et efficacement. Il connecte les employeurs
                avec des chasseurs de tête et centralise le processus de recrutement, offrant une réception rapide de candidatures qualifiées et une
                garantie de 3 mois sur les recrutements.
                <br />
                <br />
                En revanche, <strong>Headlinker</strong> est une plateforme destinée aux recruteurs. Elle leur permet de collaborer, de partager des
                primes pour les placements réussis, et d’accéder à des offres confidentielles, créant ainsi un réseau gagnant/gagnant pour les
                professionnels du recrutement.
              </p>
            </div>
          </article>
          <article itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Combien coûte Headlinker ?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                <strong>Headlinker</strong> est une plateforme <strong>soumise à un fee d’entrée</strong> pour les professionnels du recrutement. Vous
                pouvez publier vos annonces, accéder à une liste d‘offres confidentielles et proposer vos meilleurs candidats sans frais d‘inscription
                ou d‘utilisation.
                <br />
                <br />
                La plateforme fonctionne sur un modèle de paiement au succès, ce qui signifie que vous ne payez que lorsque vous trouvez le bon
                candidat pour une offre. Lorsque vous publiez une annonce, vous fixez la prime que vous verserez au recruteur qui vous trouvera le bon
                candidat.
                <br />
                <br />
                Celle-ci est divisée en 2
                <ul>
                  <li>À la prise de fonction</li>
                  <li>À la fin de la période d‘essai</li>
                </ul>
                <br />
                Headlinker prend <strong>20%</strong> sur ce montant, 10% à chaque recruteur
              </div>
            </div>
          </article>
          <article itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Exemple de partage de prime</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Je poste une mission pour un Directeur commercial et je suis prêt à donner une prime de 2000€ à la prise de poste et 3000€ à la fin de
                la période d‘essai.
                <br />
                <ul>
                  <li>Le recruteur ayant placé le candidat paye 5000€ au recruteur ayant proposé le candidat</li>
                  <li>Les 2 recruteurs payent 500€ à Headlinker</li>
                </ul>
              </div>
            </div>
          </article>
          {/* <article itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <h3 itemProp="name">Comment suis-je assuré d‘être payé ?</h3>
            <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
              <div itemProp="text">
                Headlinker agit comme tiers de confiance et nous nous occupons du contrat et de la transaction financière entre les 2 recruteurs.
                <br />
                <br />
                Notre plateforme est exclusivement réservée aux professionnels du recrutement, ce qui garantit un niveau de professionnalisme et de
                confiance élevé entre les parties.
              </div>
            </div>
          </article> */}
        </section>
        <div className="text-center mt-5">
          <Button className="btn btn-primary" onClick={toggleModal}>
            J’accède à la plateforme maintenant
          </Button>
        </div>
      </Section>
    </HeaderLayout>
  );
};

export default HomePage;
