import TestimonialList, { type TestimonialListProps } from 'modules/common/components/TestimonialList';
import { Link } from 'modules/i18n';
import React from 'react';
import { track } from '@/lib/analytics/client';
import PlanBadge from 'modules/Marketing/components/PlanBadge';
import classNames from 'classnames';
import { useQueryState } from 'nuqs';

type TestimonialOfferListProps = Omit<TestimonialListProps, 'testimonials'>;

const offers = ['starter', 'booster', 'teambooster', 'pro'];

const testimonials = [
  {
    imageSrc: 'https://files.headlinker.com/users/linkedin/icSxALKon3.jpeg',
    altText: 'Testimonial 01',
    quote: 'Headlinker: Excellent ! 1 besoin posté, 1 candidate en moins de 48h et 1 démarrage',
    author: 'Olivier Arnaud',
    company: 'CEO Arol Consulting',
    offer: 'starter',
  },
  // {
  //   // imageSrc:
  //   // 'https://media.licdn.com/dms/image/D4E03AQGh4Et-MHaVTA/profile-displayphoto-shrink_100_100/0/1698081862993?e=1709769600&v=beta&t=tI7p8QFpIugH_YvvyuAKqas6Q-zp6G5C1J0PKstsWW0path-to-image-01.jpg',
  //   altText: 'Testimonial 01',
  //   quote: 'Concept top, plateforme top',
  //   author: 'Dominique S.',
  //   company: 'Recruteur indépendant',
  // offer: 'starter',
  // },
  {
    imageSrc: 'https://files.headlinker.com/users/profile_65292ea05c2bccb4df76ce33.jpeg',
    altText: 'Testimonial 01',
    quote: 'Continuez comme ça, la base est bonne et pour beaucoup de recruteurs, la plateforme peut-être utile.',
    author: 'Olivier Rozec',
    company: 'Neoconnection',
    offer: 'starter',
  },
  {
    imageSrc: 'https://files.headlinker.com/users/linkedin/jNCcfuJZKD.jpeg',
    // altText: 'Dorothée de Calan’s Head',
    quote: 'Super concept, plateforme très propre, échanges faciles avec les autres recruteurs',
    author: 'Zahra Bentahar',
    company: 'Simone Recrute',
    offer: 'starter',
  },
  {
    // imageSrc:
    // 'https://media.licdn.com/dms/image/D4E03AQGh4Et-MHaVTA/profile-displayphoto-shrink_100_100/0/1698081862993?e=1709769600&v=beta&t=tI7p8QFpIugH_YvvyuAKqas6Q-zp6G5C1J0PKstsWW0path-to-image-01.jpg',
    altText: 'Testimonial 01',
    quote: 'Très satisfaite - plate-forme fluide et intuitive.',
    author: 'Celia L.',
    company: 'Recruteur indépendant',
    offer: 'starter',
  },
  {
    imageSrc: 'https://files.headlinker.com/users/profile_65390efc68f8fc6445f03f8d_b3a81905-cd10-47ab-8751-7a34b8a84f93.png',
    altText: 'Testimonial 01',
    quote:
      'Le concept est top que ce soit pour la publication de délégation de mission ou la proposition des candidats non retenus par nos clients.\nLes recruteurs avec lesquels j’ai pu échanger sont vraiment dans un esprit d’entraide et de collaboration avec un travail de qualité en collaboration avec l’un d’eux.\nLes primes de rétribution sont un vrai plus et permettent de se faire un complément intéressant en cas de placement',
    author: 'Fanny Fouquet Djellab',
    company: 'Hashtag Recrutement',
    offer: 'starter',
  },
  {
    // imageSrc:
    // 'https://media.licdn.com/dms/image/D4E03AQGh4Et-MHaVTA/profile-displayphoto-shrink_100_100/0/1698081862993?e=1709769600&v=beta&t=tI7p8QFpIugH_YvvyuAKqas6Q-zp6G5C1J0PKstsWW0path-to-image-01.jpg',
    altText: 'Testimonial 01',
    quote:
      'Concept qui répond à une problématique globale des recruteurs indépendants (le modèle existant déjà pour les recruteurs en entreprise), pour les talents non retenus sur le besoin initial.\nPouvoir aussi solliciter une aide ponctuelle sur des besoins plus difficiles à répondre est une bonne chose.\nPlateforme simple mais complète.',
    author: 'Malika L.',
    company: 'Recruteuse indépendante',
    offer: 'starter',
  },

  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote:
      'Bravo pour ce que vous faites 👏. On sent votre engagement, votre envie de réussir et l’attachement à l’humain derrière ce beau projet donc ça ne peut que fonctionner !\n\nJe vous souhaite une belle réussite pour vos projets à venir',
    author: 'Julien L.',
    company: 'Recruteur indépendant',
    offer: 'starter',
  },
  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote:
      'J’ai du dépublier la mission que j’avais proposée sur Headlinker au bout de 72h, j’avais déjà ma short list de 5 candidats. Ultra satisfait',
    author: 'Marc A.',
    company: 'Recruteur IT',
    offer: 'starter',
  },
  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote: 'Headlinker, c’est un collectif, sans les menottes !',
    author: 'Camille S.',
    offer: 'teambooster',
  },
  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote: 'J’ai été très étonné de la qualité des recruteurs Headlinker.',
    author: 'Lior Z.',
    offer: 'starter',
  },
  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote:
      'Les sessions de Cold Call sont géniales. Super énergie, ça donne envie de revenir toutes les semaines. Et avec la régularité, les résultats sont là',
    author: 'Elsa',
    company: 'Recruteuse BTP',
    offer: 'booster',
  },
  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote: 'Je n’arrivais plus à me discipliner pour travailler seul. Le Club Headlinker m’a vraiment reboosté',
    author: 'Pascal Rebora',
    offer: 'teambooster',
  },
  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote: 'Franchement les recruteuses pour qui j’ai sourcé sont top, super carrées, super réactives, rien à dire',
    author: 'Nicolas L.',
    company: 'Recruteur généraliste',
    offer: 'starter',
  },
  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote:
      'C’est horrible de dire ça, mais j’avais vraiment la trouille de me faire voler mes candidats. Mais vos process sont dingues, les recruteurs ultra bien sélectionnés, c’est étonnant',
    author: 'Nathalie',
    offer: 'starter',
  },
  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote:
      'J’héstais à accepter une mission en Belgique parce que je ne connais pas bien le marché. Mais j’ai pu contacter un recruteur Belge qui m’a ouvert son vivier. Tout le monde était content : mon client, le recruteur Belge, son candidat, et moi !',
    author: 'Annaelle Bellalou',
    offer: 'starter',
  },
  {
    // imageSrc:
    //   'https://demo.headlinker.com/_next/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD5603AQFwwKxJ1u2Qwg%2Fprofile-displayphoto-shrink_100_100%2F0%2F1686911624172%3Fe%3D1708560000%26v%3Dbeta%26t%3DGPGqLL_CKyIBeVaf_9psvHBmkbRCcza-lHPyIjcK0zk&w=256&q=75',
    // altText: 'Martin Ratinaud’s Head',
    quote:
      'Trop de missions en même temps, des briefs clients ultra précis, j’ai souscrit à l’abonnement Headlinker VIP dans l’urgence. J’ai pu envoyer rapidement quelques candidats à mes clients',
    author: 'Laetitia S',
    offer: 'pro',
  },

  // Add more testimonials here

  {
    imageSrc:
      'https://media.licdn.com/dms/image/v2/D4E35AQHs3ciuGD2pUg/profile-framedphoto-shrink_200_200/B4EZUnztZGGgAc-/0/1740129621905?e=1741341600&v=beta&t=LHpC562LZfXyoNMIoDrm56nP3PrR_NBuSD4_2cPFYB0',
    // altText: 'Martin Ratinaud’s Head',
    quote: 'Des market places, y’en a tous les jours qui se créent. Headlinker sort clairement du lot.',
    author: 'Quentin Decré',
    company: 'Co-Founder Jarvi',
    offer: 'starter',
  },
  {
    imageSrc: 'https://files.headlinker.com/users/profile_6673f13323ee6849abd33ad6.jpeg',
    altText: 'Mission remplie',
    quote: (
      <>
        Headlinker: dépot de mission, candidate proposée le même jour, process fini 21 jours plus tard 😍.{' '}
        <Link
          className="link link-primary text-xs"
          target="_blank"
          onClick={() => track('testimonial.romy.clicked')}
          href="https://www.linkedin.com/posts/dorothee-de-calan_aujourdhui-60-de-nos-nouveaux-utilisateurs-activity-7233765683233202176-DCg9/?utm_source=share&utm_medium=member_desktop"
        >
          Voir l’interview
        </Link>
      </>
    ),
    author: 'Romy De Jésus Leite',
    company: 'Madame RH',
    offer: 'starter',
  },
];

const TestimonialOfferList: React.FC<TestimonialOfferListProps> = (props) => {
  const [selectedOffer, setSelectedOffer] = useQueryState('testimonial-offer');

  const filteredTestimonials = React.useMemo(
    () =>
      testimonials
        .filter((testimonial) => !selectedOffer || testimonial.offer === selectedOffer)
        .map(({ offer, ...rest }) => rest)
        .reverse(),
    [selectedOffer]
  );

  return (
    <>
      <div className="text-center text-sm text-gray-500 mb-2">Filtrer les témoignages par offre</div>
      <div className="flex items-center justify-center gap-2 flex-wrap">
        {offers.map((offer) => (
          <PlanBadge
            key={offer}
            type={offer as any}
            onClick={() => {
              track('testimonial.filter.clicked', { offer });
              setSelectedOffer(selectedOffer === offer ? null : offer);
            }}
            className={classNames('cursor-pointer', selectedOffer === offer || !selectedOffer ? 'opacity-100' : 'opacity-50')}
          />
        ))}
      </div>
      <TestimonialList testimonials={filteredTestimonials} {...props} />
    </>
  );
};

export default TestimonialOfferList;
